import React, { useMemo } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { commonImgPath } from '@/shared/app-common';

const ChooseInstantKnowContent = ({ onClick }: { onClick?: () => void }) => {
  const { t } = useTranslation();

  const dataList = [
    {
      icon: `/images/home/home-website-small-icon.webp`,
      imgPath: 'home-website-big-icon.webp',
      title: `Website Content Changes and Visual Notifications`,
      desc: `Monitor website changes for timely insights, easily receive notifications, and visualize trends to enhance your team's efficiency.`,
    },
    {
      icon: `/images/home/home-email-small-icon.webp`,
      imgPath: 'home-email-big-icon.webp',
      title: `Instant Email Notifications`,
      desc: `Get instant email notifications for real-time updates and alerts, ensuring you stay informed and make effective decisions quickly.`,
    },
    {
      icon: `/images/home/home-monitor-small-icon.webp`,
      imgPath: 'home-monitor-big-icon.webp',
      title: `Regional Monitoring Service`,
      desc: `Easily monitor specific areas for targeted insights, enabling flexible strategies that deliver precise information where it matters most.`,
    },
    {
      icon: `/images/home/home-analysis-small-icon.webp`,
      imgPath: 'home-analysis-big-icon.webp',
      title: `AI Analysis and Summarization`,
      desc: `Leverage AI analysis to extract key insights, enabling a quick understanding of data changes for efficient and targeted analysis.`,
    },
  ];

  const cardItem = (item: any) => {
    return (
      <div className={styles.cardContainer} key={item?.title}>
        <div className={styles.subContainer}>
          <Image
            src={item?.icon}
            alt={''}
            width={80}
            height={80}
            style={{
              borderRadius: '12px',
              background: '#DFE6FD',
              padding: '12px'
            }} />
          <h3 className={styles.subTitle}>{item?.title}</h3>
          <p className={styles.subDesc}>{item?.desc}</p>
          <span className={styles.dashboard} onClick={onClick}>{`Get started`}</span>
        </div>

        <div className={styles.subContainer}>
          <img src={`${commonImgPath}/${item?.imgPath}`} alt="big img" />
        </div>
      </div>
    );
  };

  const getContent = useMemo(() => {
    return (
      <div className={styles.content}>
        <h2>{t('Why Choose Instant Know?')}</h2>

        <div className={styles.container}>
          {dataList.map((item, index) => {
            return cardItem(item);
          })}
        </div>
      </div>
    );
  }, [dataList]);

  return <>{getContent}</>;
};

export default ChooseInstantKnowContent;
